import {reactive, toRefs, ref} from "vue";
import axios from 'axios'
import api from '@/helpers/api'
import {env} from '@/configs'
// import {useMutation} from "@vue/apollo-composable";
// import {CREATE_PROJECT_MUTATION} from "@/graphql/mutations/projectMutation";

export const getProjectsAPI = async () => {

    try {
        const res = await api.get({
            url: env.GITLAB_API_URL + '/projects',
            data: {
                simple: true,
                per_page: 100
            }
        })

        if (res.status === 200) {
            return res.data
        }
    }
    catch (err) {
        console.error(err);
        throw new Error(err);
    }

}

export const getProjectAPI = async (ids) => {

    let result = []
    // let banned = ['pipo/kr-uterhaus', 'raphi/furter-furter-ag', 'raphi/neusa']
    let banned = ['raphi/furter-furter-ag']
    let list = ids.filter(id => !banned.includes(id) )

    try {
        await Promise.all( list.map(async (id) => {
            const res = await api.get({
                url: env.GITLAB_API_URL + '/projects/' + encodeURIComponent(id),
                data: {
                    simple: true
                }
            })

            if (res.status === 200) {
                result.push(res.data)
            }

        }))

    } catch (error) {
        console.error(error);
        // throw new Error(error);
    }


    return result;

}

export const getProjectEventsApi = async(projectID) => {

      try {
          const res = await api.get({
              url: env.GITLAB_API_URL + '/projects/' + projectID + '/events'
          })

          if (res.status === 200) {
              return res.data
          }
      }
      catch (err) {
          console.error(err);
          throw new Error(err);
      }
}


export const getProjectIssueStatisticMembersAPI = async (projectId, members) => {

        let stats = []

        await Promise.all( members.map(async (member) => {
            const res = await api.get({
                url: env.GITLAB_API_URL + `/projects/${projectId}/issues_statistics`,
                data: {
                    assignee_id: member.id,
                    scope: 'all'
                }
            })

            if (res.status === 200) {
                let { statistics : { counts: { closed, opened }} } = res.data
                let payload = { name: member.name, id: member.id, closed, opened }
                stats.push(payload)
            }

        }))

    return stats;

}

export const getProjectIssueStatisticAPI = async (projectId) => {
    const res = await api.get({
        url: env.GITLAB_API_URL + `/projects/${projectId}/issues_statistics`,
        data: {
            scope: 'all'
        }
    })

    if (res.status === 200) {

        let { statistics : { counts: { closed, opened, all }} } = res.data
        return {
            closed,
            all,
            opened
        }
    }
}

export const getProjectMembersAPI = async (projectId) => {
    const res = await api.get({
        url: env.GITLAB_API_URL + `/projects/${projectId}/members/all`,
    })

    if (res.status === 200) {
        return res.data
    }
}

export const getProjectUsersAPI = async (projectId) => {
    const res = await api.get({
        url: env.GITLAB_API_URL + `/projects/${projectId}/users`,
    })

    if (res.status === 200) {
        return res.data
    }
}

export const createProjectAPI = () => {
    let state = reactive({
        project: null,
        errors: null,
        loading: false,
    })

    const createProject = async (data) => {
        state.loading = true

        await api.post({
            url: env.GITLAB_API_URL + `/projects/`,
            data: data
        }).then(({data}) => {
            state.project = data
            state.loading = false
            state.errors = null
        }).catch(error => {
            console.log(error);
            state.error = true;
            state.project = null
        }).finally(() => (
            state.loading = false
        ));
    }

    return {
        ...toRefs(state),
        createProject
    }
}

export const issuesApi = () => {

  let state = reactive({
      issue: null,
      errors: null,
      loading: false,
  })

  const createIssue = async (id,payload) => {
    try {
        const res = await api.post({
            url: env.GITLAB_API_URL + `/projects/` + id + `/issues`,
            data: payload
        })

        if (res) {
          state.issue = res
          state.loading = false
        }

    } catch (error) {
      console.log(error);
      state.error = true;
      state.issue = null
    } finally {
      state.loading = false
    }
  }

  return {
      ...toRefs(state),
      createIssue
  }

}


export const uploadFileApi = async (id, formData) => {
    try {
        let headers = { headers: {
            'Content-Type': 'multipart/form-data'
          }
        }

        const res = await api.post_data({
            url: env.GITLAB_API_URL + `/projects/` + id + `/uploads`,
            data: formData,
            headers
        });

        if ( res.status === 201 ) {
          return res.data
        }

    } catch (error) {
      console.log(error);
    }

}

// group
export const getGroupEventsApi = () => {

      let state = reactive({
          events: null,
          errors: null,
          loading: false,
      })


      const fetch = async () => {
        try {
            const res = await api.get({
                url: env.GITLAB_API_URL + '/events',
                data: {
                  scope: 'all'
                }
            })

            if (res.status === 200) {

                console.log(res)
                state.events = res.data
            }
        }
        catch (err) {
            console.error(err);
            throw new Error(err);
        }

      }

      return {
          ...toRefs(state),
          fetch
      }

}


// users
export const getUsersApi = () => {

  let state = reactive({
      users: null,
      errors: null,
      loading: false,
  })

  const fetchUsers = async () => {
    try {
        const res = await api.get({
            url: env.GITLAB_API_URL + `/groups/dev/members`,
            data: {
              active: true
            }
        })

        if (res.status === 200) {
          state.users = res.data
          state.loading = false
        }

    } catch (error) {
      console.log(error);
      state.error = true;
      state.users = null

    } finally {
      state.loading = false
    }
  }


  fetchUsers()

  return {
      ...toRefs(state),
      fetchUsers
  }

}


export const getUserAPI = async (id) => {

    try {
        const res = await api.get({
            url: env.GITLAB_API_URL + '/users/' + id
        })

        if (res.status === 200) {
            return res.data
        }
    }
    catch (err) {
        console.error(err);
        throw new Error(err);
    }

}
